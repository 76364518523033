import { useNavigate } from '@remix-run/react';
import { useRef, useState } from 'react';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import { ArrowDownIcon } from '../icons/Arrows';
import { LunaLogo } from '../icons/LogoIcon';
import { useMyOrganizationFeatureChecker } from '../Organization/hooks/organization';

export const LunaMenuContainer = ({
  isAdmin,
}: {
  isAdmin: boolean;
}): JSX.Element => {
  const logoRef = useRef<HTMLDivElement>(null);
  const [showLogoDropdown, setShowLogoDropdown] = useState(false);
  const navigate = useNavigate();
  const learningNavPath = isAdmin ? '/learning/admin' : '/learning';
  const featureChecker = useMyOrganizationFeatureChecker();

  const isDropdownVisible =
    featureChecker.hasUnlimitedTrainingAccess() &&
    featureChecker.hasAnyOndGamePlayAccess();

  useOutsideClick(logoRef, () => {
    setShowLogoDropdown(false);
  });

  const handleLpLogoClick = () => {
    if (!isDropdownVisible) {
      return navigate(learningNavPath);
    }
    setShowLogoDropdown((prev) => !prev);
  };

  return (
    <div
      className='flex-1 h-full overflow-hidden flex items-center gap-4'
      ref={logoRef}
    >
      <div
        className='flex-none relative flex items-center gap-2 cursor-pointer'
        onClick={handleLpLogoClick}
      >
        <LunaLogo className='h-auto w-25 mr-4' />
        {isDropdownVisible && (
          <ArrowDownIcon className='w-3.5 h-3.5 fill-current self-end' />
        )}
      </div>
      {showLogoDropdown && isDropdownVisible && (
        <div className='absolute top-12 left-10 bg-black text-white rounded-xl p-1 text-2xs border border-secondary w-45'>
          <button
            type='button'
            onClick={(event) => {
              event.stopPropagation();
              navigate('/home');
              setShowLogoDropdown(false);
            }}
            className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start'
          >
            Team Building
          </button>
          <button
            type='button'
            onClick={(event) => {
              event.stopPropagation();
              navigate(learningNavPath);
              setShowLogoDropdown(false);
            }}
            className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-between'
          >
            Learning
            <div className='h-3.5 px-1.5 uppercase bg-luna-primary text-black text-sms font-bold flex items-center justify-center rounded-sm'>
              beta
            </div>
          </button>
        </div>
      )}
    </div>
  );
};
