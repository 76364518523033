import { NavLink, useNavigate } from '@remix-run/react';
import { useMemo, useRef, useState } from 'react';
import { $path } from 'remix-routes';

import { MediaFormatVersion } from '@lp-lib/media';

import { PageLoadingBar } from '../../../../../app/components/PageLoadingBar';
import { useAppAnalytics } from '../../../../analytics/app/identifiable';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { OrganizerRoleUtils } from '../../../../types';
import { MediaUtils } from '../../../../utils/media';
import { LearningHeaderAvatar } from '../../../HeaderAvatar';
import { ArrowDownIcon } from '../../../icons/Arrows';
import { DocumentIcon } from '../../../icons/DocumentIcon';
import { HouseIcon } from '../../../icons/HouseIcon';
import { MenuIcon } from '../../../icons/MenuIcon';
import { ScoreboardIcon } from '../../../icons/ScoreboardIcon';
import { StackedSquaresIcon } from '../../../icons/StackedSquaresIcon';
import { XIcon } from '../../../icons/XIcon';
import { useMyOrganizationFeatureChecker } from '../../../Organization/hooks/organization';
import { LunaMenuContainer } from '../../../PublicHome/LunaLogoMenu';
import { useUser } from '../../../UserContext';

export function LearningLayout({
  secondaryNav,
  children,
  backgroundClassName,
}: {
  secondaryNav?: React.ReactNode;
  children: React.ReactNode;
  backgroundClassName?: string;
}) {
  const user = useUser();
  const isAdminOrOrgOwner = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const logoEl = useMemo(() => {
    const logoSrc = MediaUtils.PickMediaUrl(
      user.organizer?.organization?.logo,
      {
        priority: [MediaFormatVersion.SM],
      }
    );

    if (!logoSrc || isAdminOrOrgOwner) {
      return (
        <div className='md:pl-6'>
          <LunaMenuContainer isAdmin={isAdminOrOrgOwner} />
        </div>
      );
    }

    return (
      <OrgMemberNavLogo
        orgName={user.organizer?.organization?.name ?? ''}
        orgLogo={logoSrc}
      />
    );
  }, [user.organizer, isAdminOrOrgOwner]);

  return (
    <div className='w-full h-full flex flex-col font-noto-sans'>
      <PageLoadingBar color='#23BDDF' />

      {/* Sticky top bar */}
      <div className='sticky top-0 z-40 w-full min-h-[3.75rem] bg-black text-white border-b border-secondary flex items-center justify-between px-4 md:pr-5'>
        <div className='flex items-center gap-4 md:gap-10'>{logoEl}</div>

        <div className='flex items-center gap-3'>
          {/* Desktop nav */}
          <div className='hidden md:flex items-center gap-8'>
            {isAdminOrOrgOwner ? (
              <>
                <NavItem
                  to='/learning/admin'
                  title='Home'
                  iconActiveColor='text-luna-primary'
                  end
                >
                  <div className='flex items-center gap-2'>
                    <HouseIcon />
                    <span className='text-md text-white'>Home</span>
                  </div>
                </NavItem>
                <NavItem
                  to='/learning/admin/my-courses'
                  title='My Courses'
                  iconActiveColor='text-luna-primary'
                >
                  <div className='flex items-center gap-2'>
                    <StackedSquaresIcon />
                    <span className='text-md text-white'>My Courses</span>
                  </div>
                </NavItem>
                <NavItem
                  to='/learning/admin/analytics'
                  title='Analytics'
                  iconActiveColor='text-luna-primary'
                >
                  <div className='flex items-center gap-2'>
                    <ScoreboardIcon className='fill-current w-5 h-5' />
                    <span className='text-md text-white'>Analytics</span>
                  </div>
                </NavItem>
              </>
            ) : (
              <>
                <NavItem
                  to='/learning'
                  title='Assigned to Me'
                  iconActiveColor='text-luna-primary'
                  end
                >
                  <div className='flex items-center gap-2'>
                    <DocumentIcon />
                    <span className='text-md text-white'>Assigned To Me</span>
                  </div>
                </NavItem>
              </>
            )}
          </div>

          {/* Desktop Avatar */}
          <div className='hidden md:block ml-8'>
            <LearningHeaderAvatar />
          </div>

          {/* Mobile burger menu toggler */}
          <button
            className='md:hidden p-2'
            type='button'
            onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
            aria-label='Toggle Navigation Menu'
          >
            {isMobileNavOpen ? <XIcon /> : <MenuIcon />}
          </button>
        </div>
      </div>

      {/* Mobile Nav items */}
      {isMobileNavOpen && (
        <div
          className='
            md:hidden
            fixed           
            top-[3.75rem]  
            left-0
            right-0
            z-50           
            bg-black
            border-b border-secondary
            text-white
            shadow-lg
          '
        >
          <div className='flex flex-col gap-3 px-4 py-3'>
            {isAdminOrOrgOwner && (
              <>
                <NavItem
                  to='/learning/admin'
                  title='Home'
                  end
                  onClick={() => setIsMobileNavOpen(false)}
                  iconActiveColor='text-luna-primary'
                >
                  <div className='flex items-center gap-2'>
                    <HouseIcon />
                    <span className='text-md text-white'>Home</span>
                  </div>
                </NavItem>
                <NavItem
                  to='/learning/admin/my-courses'
                  title='My Courses'
                  onClick={() => setIsMobileNavOpen(false)}
                  iconActiveColor='text-luna-primary'
                >
                  <div className='flex items-center gap-2'>
                    <StackedSquaresIcon />
                    <span className='text-md text-white'>My Courses</span>
                  </div>
                </NavItem>
                <NavItem
                  to='/learning/admin/analytics'
                  title='Analytics'
                  onClick={() => setIsMobileNavOpen(false)}
                  iconActiveColor='text-luna-primary'
                >
                  <div className='flex items-center gap-2'>
                    <ScoreboardIcon className='fill-current w-5 h-5' />
                    <span className='text-md text-white'>Analytics</span>
                  </div>
                </NavItem>
              </>
            )}
            <NavItem
              to='/learning'
              title='Assigned to Me'
              onClick={() => setIsMobileNavOpen(false)}
              iconActiveColor='text-luna-primary'
              end
            >
              <div className='flex items-center gap-2'>
                <DocumentIcon />
                <span className='text-md text-white'>Assigned To Me</span>
              </div>
            </NavItem>
          </div>
        </div>
      )}

      {secondaryNav}

      {/* Main content area */}
      <div
        className={`
          w-full flex-1
          ${backgroundClassName ?? 'bg-modal'}
          text-white
          overflow-auto
          scrollbar
        `}
      >
        {children}
      </div>
    </div>
  );
}

function NavItem(props: {
  title: string;
  to: string;
  children?: React.ReactNode;
  onClick?: () => void;
  end?: boolean;
  iconActiveColor: string;
}) {
  const appAnalytics = useAppAnalytics();

  const handleClick = () => {
    appAnalytics.trackNavItemClicked(props.title, props.to);
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        `text-sm tracking-[1.25px] ${
          isActive
            ? `font-bold ${props.iconActiveColor}`
            : 'font-medium text-white'
        }`
      }
      onClick={handleClick}
      end={props.end}
    >
      {props.children || props.title}
    </NavLink>
  );
}

function OrgMemberNavLogo(props: { orgName: string; orgLogo: string }) {
  const logoRef = useRef<HTMLDivElement>(null);
  const [showLogoDropdown, setShowLogoDropdown] = useState(false);
  const navigate = useNavigate();
  const learningNavPath = $path('/learning');
  const featureChecker = useMyOrganizationFeatureChecker();

  const isDropdownVisible =
    featureChecker.hasUnlimitedTrainingAccess() &&
    featureChecker.hasAnyOndGamePlayAccess();

  useOutsideClick(logoRef, () => {
    setShowLogoDropdown(false);
  });

  const handleLpLogoClick = () => {
    if (!isDropdownVisible) {
      return navigate(learningNavPath);
    }
    setShowLogoDropdown((prev) => !prev);
  };

  return (
    <div className='flex-1 h-full flex items-center gap-4' ref={logoRef}>
      <div className='flex-none relative flex items-center gap-2'>
        <div className='flex items-center gap-4'>
          <div className='w-7.5 h-7.5 flex-none rounded-md bg-layer-002 overflow-hidden'>
            <img
              src={props.orgLogo}
              alt='Organization Logo'
              className='w-full h-full object-cover'
            />
          </div>
          <div className='font-bold'>{props.orgName}</div>
        </div>

        {isDropdownVisible && (
          <div className='relative cursor-pointer' onClick={handleLpLogoClick}>
            <ArrowDownIcon className='w-3.5 h-3.5 fill-current' />
            {showLogoDropdown && (
              <div className='absolute top-full left-0 bg-black text-white rounded-xl p-1 text-2xs border border-secondary w-45'>
                <button
                  type='button'
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate('/home');
                    setShowLogoDropdown(false);
                  }}
                  className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-start'
                >
                  Team Building
                </button>
                <button
                  type='button'
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(learningNavPath);
                    setShowLogoDropdown(false);
                  }}
                  className='w-full p-3 hover:bg-lp-black-003 rounded-lg flex items-center justify-between'
                >
                  Learning
                  <div className='h-3.5 px-1.5 uppercase bg-lp-red-002 text-white text-sms font-bold flex items-center justify-center rounded-sm'>
                    beta
                  </div>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
